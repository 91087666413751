import './App.css';

function App() {
  return (
    <div>
      <h2>
        Register your own decentralized domain name and host websites on Everscale blockchain.
      </h2>
      <h3>
        Coming soon...
      </h3>
      <small>(last update 23 JUN 2022)</small>
    </div>
  );
}

export default App;
